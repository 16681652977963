import React, { useRef } from "react";
import cn from "classnames";
import Marquee from "react-fast-marquee";
import useFadeIn from "@src/hooks/useFadeIn";
import styles from "./index.module.scss";
import alchemy from "./assets/alchemy.svg";

import amber from "./assets/amber.svg";
import arweave from "./assets/arweave.svg";
import bbf from "./assets/bbf.png";
import dispersion from "./assets/dispersion.svg";
import hashglobal from "./assets/hashglobal.svg";
import karatage from "./assets/karatage.svg";
import inital from "./assets/inital.svg";
import lennove from "./assets/lennove.png";
import modular from "./assets/modular.svg";
import mozaik from "./assets/mozaik.svg";
import snz from "./assets/snz.svg";
import samsung from "./assets/samsung.png";
import symbolic from "./assets/symbolic.png";
import vaneck from "./assets/vaneck.png";
import maelstrom from "./assets/maelstrom.svg";
import welara from "./assets/welara.svg";
import SectionTitle from "@src/components/SectionTitle";
import blank from "./assets/blank.png"

//Symbolic, Dispersion, VanEck, Samsung Next, Alchemy Ventures, Maelstrom, BBF, Modular, Karatage, Mozaik,
// Arweave scp，Amber, SNZ, Hashglobal, 联想
const logoIcons = [
  blank,
  symbolic,
  dispersion,
  vaneck,
  samsung,
  alchemy,
  maelstrom,
  welara,
  bbf,
  modular,
  karatage,
  inital,
  mozaik,
  arweave,
  amber,
  snz,
  hashglobal,
  lennove,
];

export default () => {
  const ref = useRef(null);
  const [visible] = useFadeIn(ref);
  return (
    <div className={styles.root}>
      <div className="flex center">
        <SectionTitle visible={visible} transition ref={ref}>
          Backed by
        </SectionTitle>
      </div>

      <Marquee speed={50} pauseOnHover className={cn(styles.marquee)}>
        {logoIcons.map((logoSrc, index) => {
          return (
            <img
              key={index}
              alt="logo"
              src={logoSrc}
              className={styles.swiperItem}
            />
          );
        })}
      </Marquee>
    </div>
  );
};
